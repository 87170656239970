import { createSlice } from "@reduxjs/toolkit";

import Youtube from '../assets/images/icon-youtube.svg';
import Instagram from '../assets/images/icon-instagram.svg';
import Naverblog from '../assets/images/icon-naverblog.svg';

const initialState = [
  {
    id: 0,
    text: "유튜브",
    icon: Youtube,
    href: "https://www.youtube.com/@user-jr2wh1kn1j",
  },
  {
    id: 1,
    text: "네이버 블로그",
    icon: Naverblog,
    href: "https://blog.naver.com/youngkyun_",
  },
  /*
  {
    id: 1,
    text: "인스타그램",
    icon: Instagram,
    href: "/",
  },
  */
];

const socialMediaSlice = createSlice({
  name: "socialMedia",
  initialState,
  reducers: {},
});

export default socialMediaSlice.reducer;
